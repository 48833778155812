var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"px-2"},[_c('Comeback'),_vm._v(" "+_vm._s(_vm.$t('CourseServiceReport'))+" "),_c('v-spacer'),_c('v-btn',{staticClass:"d-none d-md-block",attrs:{"color":"primary","loading":_vm.exportLoading,"disabled":_vm.exportLoading},on:{"click":function($event){_vm.typeSelection=='1'?_vm.exportExcel2():_vm.exportExcel()}}},[_vm._v(" "+_vm._s(_vm.$t('excel'))+" ")]),_c('v-btn',{staticClass:"d-block d-md-none",attrs:{"color":"primary","loading":_vm.exportLoading,"disabled":_vm.exportLoading,"icon":"","fab":"","outlined":""},on:{"click":function($event){_vm.typeSelection=='1'?_vm.exportExcel2():_vm.exportExcel()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiFileExcelOutline))])],1)],1),_c('DateFilters',{attrs:{"default-status":2},on:{"onSendDate":_vm.addPayload}}),_c('v-row',{staticClass:"px-2"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6","lg":"3"}},[_c('v-select',{attrs:{"items":_vm.typeList,"outlined":"","dense":"","label":_vm.$t('formatReport'),"item-text":"name","item-value":"value"},on:{"change":function($event){return _vm.addPayload(_vm.payload.start, _vm.payload.end)}},model:{value:(_vm.typeSelection),callback:function ($$v) {_vm.typeSelection=$$v},expression:"typeSelection"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.typeSelection=='1'?_vm.columns2: _vm.columns,"items":_vm.dataTableList,"options":_vm.options,"loading":_vm.loading,"disable-sort":"","hide-default-footer":"","loading-text":_vm.$t('data_loading'),"no-data-text":_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.course_type",fn:function(ref){
var item = ref.item;
return [(item.course_type)?_c('CourseType',{attrs:{"type":String(item.course_type)}}):_vm._e()]}},{key:"item.serve_code",fn:function(ref){
var item = ref.item;
return [(item.serve_code)?_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'showDetailService', params: { id: item.serve_id } },"target":"_blank"}},[_vm._v(" "+_vm._s(item.serve_code)+" ")]):_vm._e()]}},{key:"item.customer_id",fn:function(ref){
var item = ref.item;
return [(item.customer_id)?_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'DetailCustomer', params: { id: item.customer_id_pri } },"target":"_blank"}},[_vm._v(" "+_vm._s(item.customer_id)+" ")]):_vm._e()]}},{key:"item.order_id",fn:function(ref){
var item = ref.item;
return [(item.order_id)?_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'order-detail', params: { id: item.order_id_pri } },"target":"_blank"}},[_vm._v(" "+_vm._s(item.order_id)+" ")]):_vm._e()]}},{key:"item.serve_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("sumdatetime")(item.serve_date))+" ")]}},{key:"item.order_create",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("sumdatetime")(item.order_create))+" ")]}},{key:"item.course_name",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.course_name == _vm.$t('total') ? 'font-weight-bold primary--text' : ''},[_vm._v(" "+_vm._s(item.course_name)+" ")])]}},{key:"item.course_price",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.course_name == _vm.$t('total') ? 'font-weight-bold primary--text' : ''},[_vm._v(" "+_vm._s(item.course_price)+" ")])]}},{key:"item.drug_amount",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.course_name == _vm.$t('total') ? 'font-weight-bold primary--text' : ''},[_vm._v(" "+_vm._s(item.drug_amount)+" ")])]}},{key:"item.drug_cost",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.course_name == _vm.$t('total') ? 'font-weight-bold primary--text' : ''},[_vm._v(" "+_vm._s(item.drug_cost)+" ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }