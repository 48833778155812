<template>
  <div>
    <v-card>
      <v-card-title class='px-2'>
        <Comeback />
        {{ $t('CourseServiceReport') }}
        <v-spacer></v-spacer>
        <v-btn
          color='primary'
          :loading='exportLoading'
          class='d-none d-md-block'
          :disabled='exportLoading'
          @click="typeSelection=='1'?exportExcel2():exportExcel()"
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color='primary'
          class='d-block d-md-none'
          :loading='exportLoading'
          :disabled='exportLoading'
          icon
          fab
          outlined
          @click="typeSelection=='1'?exportExcel2():exportExcel()"
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters :default-status='2' @onSendDate='addPayload' />
      <v-row class='px-2'>
        <v-col cols='12' md='6' lg='3' class='py-0'>
          <v-select
            v-model='typeSelection'
            :items='typeList'
            outlined
            dense
            :label="$t('formatReport')"
            item-text='name'
            item-value='value'
            @change='addPayload(payload.start, payload.end)'
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="typeSelection=='1'?columns2: columns"
        :items='dataTableList'
        :options.sync='options'
        :loading='loading'
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.course_type`]='{ item }'>
          <CourseType v-if='item.course_type' :type='String(item.course_type)' />
        </template>
        <template v-slot:[`item.serve_code`]='{ item }'>
          <router-link
            v-if='item.serve_code'
            :to="{ name: 'showDetailService', params: { id: item.serve_id } }"
            class='font-weight-medium text-decoration-none'
            target='_blank'
          >
            {{ item.serve_code }}
          </router-link>
        </template>
        <template v-slot:[`item.customer_id`]='{ item }'>
          <router-link
            v-if='item.customer_id'
            :to="{ name: 'DetailCustomer', params: { id: item.customer_id_pri } }"
            class='font-weight-medium text-decoration-none'
            target='_blank'
          >
            {{ item.customer_id }}
          </router-link>
        </template>
        <template v-slot:[`item.order_id`]='{ item }'>
          <router-link
            v-if='item.order_id'
            :to="{ name: 'order-detail', params: { id: item.order_id_pri } }"
            class='font-weight-medium text-decoration-none'
            target='_blank'
          >
            {{ item.order_id }}
          </router-link>
        </template>
        <template v-slot:[`item.serve_date`]='{ item }'>
          {{ item.serve_date | sumdatetime }}
        </template>
        <template v-slot:[`item.order_create`]='{ item }'>
          {{ item.order_create | sumdatetime }}
        </template>
        <template v-slot:[`item.course_name`]='{ item }'>
          <span :class="item.course_name == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.course_name }}
          </span>
        </template>
        <template v-slot:[`item.course_price`]='{ item }'>
          <span :class="item.course_name == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.course_price }}
          </span>
        </template>
        <template v-slot:[`item.drug_amount`]='{ item }'>
          <span :class="item.course_name == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.drug_amount }}
          </span>
        </template>
        <template v-slot:[`item.drug_cost`]='{ item }'>
          <span :class="item.course_name == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.drug_cost }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import Comeback from '../Comeback.vue'
import { i18n } from '@/plugins/i18n'
import { reportServe2, reportServe, removeComma } from '../useExcel'
import { sumdate, sumdatetime } from '@/plugins/filters'
import CourseType from '@/components/basicComponents/CourseType.vue'
import { join } from 'path'

export default {
  components: {
    CourseType,
    DateFilters,
    Comeback,
  },
  filters: {
    sumdatetime,
  },

  setup() {
    const XLSX = require('xlsx')
    const payload = ref({})
    const loading = ref(false)
    const dataTableList = ref([])
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('service_number'), value: 'serve_code', width: 140 },
      { text: i18n.t('receipt_number'), value: 'order_id', width: 140 },
      { text: i18n.t('customer_code'), value: 'customer_id', width: 140 },
      { text: i18n.t('contacts'), value: 'customer_fullname', width: 180 },
      { text: i18n.t('tel'), value: 'customer_tel', width: 140 },
      { text: i18n.t('dateCreateOrder'), value: 'order_create', width: 180 },
      { text: i18n.t('service_date'), value: 'serve_date', width: 180 },
      { text: i18n.t('type'), value: 'course_type', width: 140 },
      { text: i18n.t('code'), value: 'course_id', width: 100 },
      { text: i18n.t('course_name'), value: 'course_name', width: 180 },
      { text: i18n.t('course_price'), value: 'course_price', width: 180, align: 'end' },
      { text: i18n.t('number_of_times'), value: 'course_amount', width: 180, align: 'center' },
      { text: i18n.t('drug_code'), value: 'drug_id', width: 180 },
      { text: i18n.t('drug_name'), value: 'drug_name', width: 180 },
      { text: i18n.t('amount'), value: 'drug_amount', width: 180, align: 'end' },
      { text: i18n.t('unit'), value: 'drug_unit', width: 180, align: 'end' },
      { text: i18n.t('cost_price'), value: 'drug_cost', width: 180, align: 'end' },
      { text: i18n.t('doctorOfQueue'), value: 'doctor_fullname', width: 180 },
      { text: i18n.t('operator'), value: 'user_fullname', width: 180 },
    ])
    const columns2 = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('service_number'), value: 'serve_code', width: 140 },
      { text: i18n.t('receipt_number'), value: 'order_id', width: 140 },
      { text: i18n.t('code'), value: 'course_id', width: 100 },
      { text: i18n.t('list'), value: 'course_name', width: 180 },
      {
        text: i18n.t('firstname_lastname'), value: 'customer_fullname', width: 200,
      },
      { text: i18n.t('tel'), value: 'customer_tel', width: 120 },
      { text: i18n.t('doctor_inspector'), value: 'user_fullname', width: 180 },
      { text: i18n.t('service_date'), value: 'serve_date', width: 180 },
      {
        text: i18n.t('sale_price'), value: 'order_totalpay', width: 150, align: 'end',
      },
      {
        text: `${i18n.t('number_of_times')}`, value: 'course_total', width: 120, align: 'center',
      },
      {
        text: `${i18n.t('timePerUse')}`, value: 'course_amount', width: 120, align: 'center',
      },
      {
        text: `${i18n.t('total_price')}/${i18n.t('times')}`,
        value: 'course_cost',
        width: 120,
        align: 'end',
      },
      {
        text: `${i18n.t('drugEquipmentCost')}`, value: 'drugorder_cost', width: 150, align: 'end',
      },
      {
        text: `${i18n.t('feeCost')}`, value: 'course_costdoctor', width: 150, align: 'end',
      },
      {
        text: `${i18n.t('totalCost')}`, value: 'cost_total', width: 150, align: 'end',
      },
      {
        text: `${i18n.t('net_profit')}`, value: 'net_profit', width: 150, align: 'end',
      },
      {
        text: i18n.t('note'), value: 'serve_comment', width: 200, align: 'end',
      },

    ])
    const options = ref({})
    const exportLoading = ref(false)
    const typeSelection = ref('1')
    const typeList = ref([{
      name: i18n.t('not_show_drug_list'),
      value: '1',
    }, {
      name: i18n.t('show_drug_list'),
      value: '2',
    }])

    const addPayload = (start, end) => {
      payload.value = {
        start,
        end,
        lang: i18n.locale,
      }
      if (typeSelection.value == '1') {
        searchReport(payload.value)
      } else {
        searchReport2(payload.value)
      }
    }

    const searchReport = async payload => {
      loading.value = true
      dataTableList.value = []
      const { data } = await reportServe2(payload)
      dataTableList.value = data
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }
    const searchReport2 = async payload => {
      dataTableList.value = []
      loading.value = true
      const { data } = await reportServe(payload)
      dataTableList.value = data
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const mapData = data => {
      data = data.map(item => {
        delete item.serve_id
        delete item.order_id_pri
        delete item.course_type
        delete item.customer_id_pri

        return item
      })

      return data
    }

    const mapData2 = data => {
      data = data.map(item => {
        delete item.serve_id
        delete item.order_id_pri
        item.customer_tags = `${item.customer_tags ? item.customer_tags : ''}`

        return item
      })

      return data
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const { start, end } = payload.value
      const dataExport1 = mapData(JSON.parse(JSON.stringify(dataTableList.value)))
      const dataExport = await removeComma(JSON.parse(JSON.stringify(dataExport1)))
      const fileName = `${i18n.t('CourseServiceReport')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t('to')} ${sumdate(
        end,
      )} .xlsx`

      // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx

      const Heading = [
        [`${i18n.t('CourseServiceReport')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t('to')} ${sumdate(end)} `],
        [
          '#',
          `${i18n.t('service_number')}`,
          `${i18n.t('receipt_number')}`,
          `${i18n.t('customer_code')}`,
          `${i18n.t('contacts')}`,
          `${i18n.t('tel')}`,
          `${i18n.t('dateCreateOrder')}`,
          `${i18n.t('service_date')}`,
          `${i18n.t('type')}`,
          `${i18n.t('code')}`,
          `${i18n.t('course_name')}`,
          `${i18n.t('course_price')}`,
          `${i18n.t('number_of_times')}`,
          `${i18n.t('drug_code')}`,
          `${i18n.t('drug_name')}`,
          `${i18n.t('amount')}`,
          `${i18n.t('unit')}`,
          `${i18n.t('cost_price')}`,
          `${i18n.t('doctorOfQueue')}`,
          `${i18n.t('operator')}`,
          `${i18n.t('note')}`,
          `${i18n.t('customer_tag')}`,
        ],
      ]
      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: [
          'number',
          'serve_code',
          'order_id',
          'customer_id',
          'customer_fullname',
          'customer_tel',
          'order_create',
          'serve_date',
          'course_type_text',
          'course_id',
          'course_name',
          'course_price',
          'course_amount',
          'drug_id',
          'drug_name',
          'drug_amount',
          'drug_unit',
          'drug_cost',
          'doctor_fullname',
          'user_fullname',
          'serve_comment',
          'tags',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 20 } }]
      const wscols = [
        { wch: 6 },
        { wch: 12 },
        { wch: 12 },
        { wch: 12 },
        { wch: 25 },
        { wch: 16 },
        { wch: 16 },
        { wch: 15 },
        { wch: 15 },
        { wch: 18 },
        { wch: 25 },
        { wch: 20 },
        { wch: 12 },
        { wch: 18 },
        { wch: 18 },
        { wch: 25 },
        { wch: 20 },
        { wch: 25 },
        { wch: 25 },
        { wch: 25 },
        { wch: 25 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('CourseServiceReport')}`)

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)

      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    const exportExcel2 = async () => {
      exportLoading.value = true
      const { start, end } = payload.value
      const dataExport1 = mapData2(JSON.parse(JSON.stringify(dataTableList.value)))
      const dataExport = await removeComma(JSON.parse(JSON.stringify(dataExport1)))
      const fileName = `${i18n.t('CourseServiceReport')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t('to')} ${sumdate(end)} .xlsx`

      // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
      /*  รายงานวิเคราะห์รายการตรวจ */
      const Heading = [
        [`${i18n.t('CourseServiceReport')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t('to')} ${sumdate(end)} `],
        [
          '#',
          `${i18n.t('service_number')}`,
          `${i18n.t('receipt_number')}`,
          `${i18n.t('code')}`,
          `${i18n.t('course_name')}`,
          `${i18n.t('course_type')}`,
          `${i18n.t('customer_code')}`,
          `${i18n.t('firstname_lastname')}`,
          `${i18n.t('tel')}`,
          `${i18n.t('id_card')}`,
          `${i18n.t('gender')}`,
          `${i18n.t('email')}`,
          `${i18n.t('blood_type')}`,
          `${i18n.t('birthday')}`,
          `${i18n.t('age')}`,
          `${i18n.t('address')}`,
          `${i18n.t('doctor_inspector')}`,
          `${i18n.t('service_date')}`,
          `${i18n.t('sale_price')}`,
          `${i18n.t('number_of_times')}`,
          `${i18n.t('timePerUse')}`,
          `${i18n.t('total_price')}/${i18n.t('times')}`,
          `${i18n.t('drugEquipmentCost')}`,
          `${i18n.t('feeCost')}`,
          `${i18n.t('totalCost')}`,
          `${i18n.t('net_profit')}`,
          `${i18n.t('note')} `,
          `${i18n.t('tag')}`,
        ],
      ]

      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: [
          'number',
          'serve_code',
          'order_id',
          'course_id',
          'course_name',
          'course_type',
          'customer_id',
          'customer_fullname',
          'customer_tel',
          'customer_idcard',
          'customer_gender',
          'customer_email',
          'customer_blood',
          'customer_birthdate',
          'customer_age',
          'customer_address',
          'user_fullname',
          'serve_date',
          'order_totalpay',
          'course_total',
          'course_amount',
          'course_cost',
          'drugorder_cost',
          'course_costdoctor',
          'cost_total',
          'net_profit',
          'serve_comment',
          'customer_tags',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 20 } }]
      const wscols = [
        { wch: 6 },
        { wch: 12 },
        { wch: 12 },
        { wch: 25 },
        { wch: 12 },
        { wch: 20 },
        { wch: 15 },
        { wch: 18 },
        { wch: 8 },
        { wch: 20 },
        { wch: 12 },
        { wch: 18 },
        { wch: 12 },
        { wch: 40 },
        { wch: 25 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        `${i18n.t('CourseServiceReport')}`,
      )

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)

      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      payload,
      loading,
      dataTableList,
      columns,
      options,
      exportLoading,
      typeSelection,
      typeList,
      exportExcel,
      columns2,
      addPayload,
      exportExcel2,
      searchReport,
      mdiFileExcelOutline,
    }
  },
}
</script>
